* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body,
.wrapper {
  height: 100vh;
  box-sizing: border-box;
  width: 100vw;
  margin: 0;
  padding: 0;
  opacity: 0.98;
}

body {
  font-family: 'Epilogue', sans-serif;
  background-color: #000;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  opacity: 0.9;
}

.logo-container {
  margin-top: 95px;
  display: flex;
  justify-content: center;
  align-items: center;

  .dark-blue-cave-logo {
    display: flex;
    background-image: url("./../../assets/images/DarkBlueCaveLogo.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    z-index: 1;
    background-color: #000;
  }
}

footer {
  .footer-container {
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vw 2vw 1vw 2vw;

    .email-container {
      transition: opacity .235s ease-in-out;

      .email-link {
        font-size: 24px;
        letter-spacing: 3px;
        display: inline-block;
        border-radius: 5px;
        background-color: #000000;
        color: #72c8f1;
        text-decoration: none;
        transition: opacity .235s ease-in-out;
      }

      a:hover {
        opacity: 0.5;
      }
    }

    .footer-links {
      text-align: center;
      list-style-type: none;

      li {
        display: inline-block;

        &:nth-of-type(n+2) {
          margin-left: 12px;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #72c8f1;
        height: 30px;
        width: 30px;
        border: 1px solid;
        border-radius: 50%;
        transition: opacity .235s ease-in-out;
        text-decoration: none;

        &:hover {
          opacity: .5;
        }
      }

      .fa {
        vertical-align: middle;
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-width: 380px) and (max-width: 599px) {

  .logo-container {
    margin-top:0 ;
    .dark-blue-cave-logo {
      height: 250px;
      width: 100%;
    }
  }

  footer {
    .footer-container {
      padding: 0 15px 0 15px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 10px;
      .footer-links {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        padding: 0;
        a {
          width: 25px;
          height: 25px;
        }
      }

      .email-container {
        margin-bottom: 10px;
        .email-link {
          font-size: 0.95rem;
          display: inline-block;
          padding: 0;
          letter-spacing: 0;
          border-radius: 5px;
          background-color: #000000;
          color: #72c8f1;
          text-decoration: none;
        }

        .email-link:hover {
          background-color: #100000;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1281px) { /* tablet, landscape iPad, lo-res laptops ands desktops */
  .logo-container {
    .dark-blue-cave-logo {
      height: 250px;
      width: 100%;
    }
  }

  footer {
    .footer-container {
      padding: 0 15px 0 15px;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .footer-links {
        display: flex;
        width: 280px;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 10px;
        a {
          width: 30px;
          height: 30px;
        }
      }

      .email-container {
        margin-bottom: 10px;
        .email-link {
          display: inline-block;
          padding: 0;
          letter-spacing: 0;
          border-radius: 50%;
          background-color: #000000;
          color: #72c8f1;
          text-decoration: none;
        }

        .email-link:hover {
          background-color: #100000;
        }
      }
    }
  }
}

